import PostList from "~/components/PostList";
import Title from "~/components/Title";
import styles from "~/routes/artigos.module.scss";

export default function Posts() {
  return (
    <PostList>
      <Title>Todos os artigos</Title>
      <h1 class={styles.title}>Todos os artigos</h1>
    </PostList>
  );
}
